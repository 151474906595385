<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Detail Vendor/Konsultan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Detail Vendor/Konsultan",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Vendor",
          href: "/monitoring/vendor",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      row_dokumenn_pertanggung_jawaban: [],

      // variable Page
      master_departement: [],
      master_departemen_selected: "",
      tipe_selected: "",
      master_kategori: [],
      master_kategori_selected: "",
      master_kategori_selected_lainnya: false,
      master_user: [],
      master_user_selected: [],
      file_spk: "",
      file_penilaian: "",
      row_dokumen_pendukung: [],

      // saved data
      nama_kegiatan: "",
      nama_vendor: "",
      tanggal_mulai_pelaksanaan: "",
      tanggal_selesai_pelaksanaan: "",
      penjelasan_singkat: "",
      nilai_jasa_vendor: "",
      pic_vendor: "",
      no_pic_vendor: "",
      penjelasan_keuntungan: "",
      nominal_akhir: "",
      is_fee_pajak: "",
      termin_pembayaran: "",
      is_projek_terlaksana: "",
      catatan_project: "",
      dasar_pertimbangan: "",
      penilaian_hasil_selected: "",
      keterangan_penilaian: "",
      vendor_id: this.$route.params.id,
      row_dokumen_pendukung_db: "",

      showRealisasiPembayaran: false,
      realisasi_pembayaran: [],
      bukti_transfer_db: "",

      //upload konfigurasi
      max_upload_size: 0,
      label_upload_tipe: "*",
      textlabel_upload_tipe: "all",
    };
  },
  mounted() {
    this.getVendor();
    this.getDepartemen();
    this.getUser();
    this.getKonfigurasiEkstensi();
  },
  methods: {
    getKonfigurasiEkstensi() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/konfigurasi_ekstensi?menu=monitoring"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var data_ekstensi = response_data.list_data.data[0];
            var ekstensi = JSON.parse(data_ekstensi.value_extension);
            self.label_upload_tipe = ekstensi
              .map((value) => value.label)
              .join(",");
            self.textlabel_upload_tipe = ekstensi
              .map((value) => value.value)
              .join(",");
            self.max_upload_size = data_ekstensi.max_upload_size;
          }
        });
    },
    getVendor() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/monitoring/vendor?id=" +
            self.vendor_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data.data[0];
          //console.log(response_data_fix);
          if (response_data.code == 200) {
            self.nama_kegiatan = response_data_fix.nama_kegiatan;
            self.nama_vendor = response_data_fix.nama;
            self.tanggal_mulai_pelaksanaan = response_data_fix.tanggal;
            self.tanggal_selesai_pelaksanaan =
              response_data_fix.tanggal_selesai;
            self.penjelasan_singkat = response_data_fix.penjelasan_singkat;
            self.nilai_jasa_vendor = self.numberFormat(
              response_data_fix.nilai_jasa
            );
            self.tipe_selected = response_data_fix.kategori;
            // self.master_kategori_selected = response_data_fix.tipe_id;
            // self.master_user_selected = response_data_fix.pic;
            self.pic_vendor = response_data_fix.pic_vendor;
            self.no_pic_vendor = response_data_fix.pic_nomor_vendor;
            self.penjelasan_keuntungan =
              response_data_fix.penjelasan_keuntungan;
            self.file_spk = response_data_fix.spk;
            // self.row_dokumen_pendukung = response_data_fix.files;
            self.nominal_akhir = self.numberFormat(
              response_data_fix.nominal_akhir
            );
            self.file_penilaian = response_data_fix.penilaian;
            self.termin_pembayaran = response_data_fix.termin_pembayaran;
            self.catatan_project = response_data_fix.catatan_kegiatan;
            self.dasar_pertimbangan = response_data_fix.dasar_pertimbangan;
            self.penilaian_hasil_selected = response_data_fix.penilaian_hasil;
            self.keterangan_penilaian = response_data_fix.keterangan_penilaian;

            if (response_data_fix.is_pajak == 1) {
              self.is_fee_pajak = "Ya";
            } else {
              self.is_fee_pajak = "Tidak";
            }

            if (response_data_fix.is_kegiatan_terlaksana == 1) {
              self.is_projek_terlaksana = "Ya";
            } else {
              self.is_projek_terlaksana = "Tidak";
            }
            var json_response_files = JSON.parse(response_data_fix.files);
            let clear_data_file = [];
            $.each(
              json_response_files,
              function (indexInArray, valueOfElement) {
                clear_data_file.push({
                  dokumen_pertanggung_jawaban_id: null,
                  name: valueOfElement.name,
                  dir: valueOfElement.dir,
                });
              }
            );
            self.row_dokumen_pendukung = clear_data_file;
            self.row_dokumen_pendukung_db = self.row_dokumen_pendukung.length;

            var json_response_user = JSON.parse(response_data_fix.pic);
            let clear_data_user = [];
            $.each(json_response_user, function (indexInArray, valueOfElement) {
              clear_data_user.push({
                id: valueOfElement.id,
                name: valueOfElement.name,
              });
            });
            self.master_user_selected = clear_data_user;

            // GET DATA DEPARTEMEN
            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                  "/api/master/departemen?&id=" +
                  response_data_fix.department_id
              )
              .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.list_data.data[0];
                if (response_data.code == 200) {
                  self.master_departemen_selected = response_data_fix;
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                  });
                }
              });

            // GET DATA MASTER KATEGORI BY TIPE
            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                  "/api/master/tipe?status=ENABLE&tipe=" +
                  response_data_fix.kategori
              )
              .then((response) => {
                var response_data = response.data;
                if (response_data.code == 200) {
                  self.master_kategori = response_data.list_data.data;
                  self.master_kategori.push({ id: "0", tipe: "Lainnya" });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                  });
                }
              });

            // GET DATA MASTER KATEGORI SELECTED
            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                  "/api/master/tipe?status=ENABLE&id=" +
                  response_data_fix.tipe_id
              )
              .then((response) => {
                var response_data = response.data;
                if (response_data.code == 200) {
                  self.master_kategori_selected =
                    response_data.list_data.data[0];
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                  });
                }
              });

            if (response_data_fix.realisasi_pembayaran) {
              self.showRealisasiPembayaran = true;
              var json_response_realisasi = JSON.parse(
                response_data_fix.realisasi_pembayaran
              );
              self.realisasi_pembayaran = json_response_realisasi;
              self.bukti_transfer_db = self.realisasi_pembayaran.length;
            }

            Swal.close();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getDepartemen() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/departemen?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_departement = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getUser() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/pic")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    // dokumen pendukung
    addRowDokumenPendukung() {
      this.row_dokumen_pendukung.push({
        dokumen_pertanggung_jawaban_id: null,
        name: null,
        dir: null,
      });
    },

    removeRowDokumenPendukung(key_deleted) {
      this.row_dokumen_pendukung.splice(key_deleted, 1);
    },

    tipeVendorChange() {
      let self = this;
      var tipe = self.tipe_selected;
      self.master_kategori = [];
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/tipe?status=ENABLE&tipe=" +
            tipe
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_kategori = response_data.list_data.data;
            self.master_kategori.push({ id: "0", tipe: "Lainnya" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    kategoriChange() {
      let self = this;
      var id_kategori = self.master_kategori_selected?.id;
      if (id_kategori == 0) {
        self.master_kategori_selected_lainnya = true;
      } else {
        self.master_kategori_selected_lainnya = false;
      }
    },

    uploadFileSPK() {
      let self = this;
      if ($("#uploadFileSPK")[0].files[0]) {
        if ($("#uploadFileSPK")[0].files[0].size < self.max_upload_size) {
          $("#uploadLoadingFileSPK").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#uploadFileSPK")[0].files[0]);
          data.append("dir", "spk");
          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoadingFileSPK").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.file_spk = dir_name;
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },
    uploadFileDokumenPendukung(row, name) {
      let self = this;
      if ($("#uploadFileDokumenPendukung" + row + "")[0].files[0]) {
        if (
          $("#uploadFileDokumenPendukung" + row + "")[0].files[0].size <
          self.max_upload_size
        ) {
          $("#uploadLoadingDokumenPendukung" + row + "").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();

          data.append(
            "file",
            $("#uploadFileDokumenPendukung" + row + "")[0].files[0]
          );
          data.append("dir", "file");
          data.append("nama_dokumen", name);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoadingDokumenPendukung" + row + "").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            // self.file_dokumen_pendukung = dir_name;
            self.row_dokumen_pendukung[row].dir = dir_name;
            // $("#valUploadFileDokumenPendukung"+row+"").val(dir_name);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },

    uploadFilePenilaian() {
      let self = this;
      if ($("#uploadFilePenilaian")[0].files[0]) {
        if ($("#uploadFilePenilaian")[0].files[0].size < self.max_upload_size) {
          $("#uploadLoadingFilePenilaian").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#uploadFilePenilaian")[0].files[0]);
          data.append("dir", "file");
          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoadingFilePenilaian").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.file_penilaian = dir_name;
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    StoreData() {
      let self = this;
      var departemen_id = self.master_departemen_selected?.id;
      if (departemen_id) {
        departemen_id = self.master_departemen_selected?.id;
      } else {
        departemen_id = "";
      }

      var kategori_id = self.master_kategori_selected?.id;
      if (kategori_id) {
        kategori_id = self.master_kategori_selected?.id;
      } else {
        kategori_id = "";
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id_vendor", self.vendor_id);
      data.append("nama_kegiatan", self.nama_kegiatan);
      data.append("nama_vendor", self.nama_vendor);
      data.append("tanggal_mulai_pelaksanaan", self.tanggal_mulai_pelaksanaan);
      data.append(
        "tanggal_selesai_pelaksanaan",
        self.tanggal_selesai_pelaksanaan
      );
      data.append("penjelasan_singkat", self.penjelasan_singkat);
      data.append("nilai_jasa_vendor", self.nilai_jasa_vendor);
      data.append("departemen_id", departemen_id);
      data.append("tipe_selected", self.tipe_selected);
      data.append("kategori_id", kategori_id);
      data.append(
        "master_user_selected",
        JSON.stringify(self.master_user_selected)
      );
      data.append("pic_vendor", self.pic_vendor);
      data.append("no_pic_vendor", self.no_pic_vendor);
      data.append("penjelasan_keuntungan", self.penjelasan_keuntungan);
      data.append("file_spk", self.file_spk);
      data.append(
        "row_dokumen_pendukung",
        JSON.stringify(self.row_dokumen_pendukung)
      );
      data.append("nominal_akhir", self.nominal_akhir);
      data.append("is_fee_pajak", self.is_fee_pajak);
      data.append("file_penilaian", self.file_penilaian);
      data.append("termin_pembayaran", self.termin_pembayaran);
      data.append("is_projek_terlaksana", self.is_projek_terlaksana);
      data.append("catatan_project", self.catatan_project);
      data.append("dasar_pertimbangan", self.dasar_pertimbangan);
      data.append("penilaian_hasil_selected", self.penilaian_hasil_selected);
      data.append("keterangan_penilaian", self.keterangan_penilaian);
      data.append(
        "realisasi_pembayaran",
        JSON.stringify(self.realisasi_pembayaran)
      );

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/monitoring/vendor/update",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman monitoring vendor/konsultan segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "vendor" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    numberFormat(bilangan) {
      if (parseFloat(bilangan) === 0) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Input tidak boleh 0 pertama kali",
        });
        return bilangan = null;
      }
      var number_string = bilangan.replace(/[^,\d]/g, "").toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);
      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      return rupiah;
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += self.file_spk;
      link.href = urlres;
      link.click();
    },

    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },

    uploadFileDokumenBuktiTransfer(row) {
      let self = this;
      if ($("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0]) {
        if (
          $("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0].size <
          5000000
        ) {
          $("#uploadLoadingDokumenBuktiTransfer" + row + "").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();

          data.append(
            "file",
            $("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0]
          );
          data.append("dir", "vendor/bukti_transfer");

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoadingDokumenBuktiTransfer" + row + "").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            
            // self.file_dokumen_pendukung = dir_name;
            self.realisasi_pembayaran[row].bukti_bayar = dir_name;
            // $("#valUploadFileDokumenPJ"+row+"").val(dir_name);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        }
      }
    },
    pilihTerminPembayaran() {
      this.realisasi_pembayaran = [];
      this.bukti_transfer_db = "";
      const total_termin = this.termin_pembayaran;
      if (total_termin > 0) {
        this.showRealisasiPembayaran = true;
      }
      for (let i = 0; i < total_termin; i++) {
        this.realisasi_pembayaran.push({
          tanggal_bayar: "",
          bukti_bayar: "",
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-tabs
                    justified
                    nav-class="nav-tabs-custom"
                    content-class="p-3 text-muted"
                  >
                    <!-- Kategori Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Data Awal</span>
                      </template>
                      <div class="p-2">
                        <table
                          class="table mb-0 table-bordered table-condensed table-hover"
                        >
                          <thead>
                            <tr class="bg-light">
                              <th colspan="4">Data Awal</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Nama Kegiatan</td>
                              <td colspan="3">
                                <b-form-input
                                  type="text"
                                  v-model="nama_kegiatan"
                                ></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Nama Vendor/Konsultan</td>
                              <td colspan="3">
                                <b-form-input
                                  type="text"
                                  v-model="nama_vendor"
                                ></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Tanggal Pelaksanaan</td>
                              <td colspan="3">
                                <table class="table" style="padding: 0px">
                                  <tbody>
                                    <tr>
                                      <td>Tanggal Mulai</td>
                                      <td>Tanggal Selesai</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b-form-input
                                          class="form-control"
                                          type="date"
                                          v-model="tanggal_mulai_pelaksanaan"
                                        ></b-form-input>
                                      </td>
                                      <td>
                                        <b-form-input
                                          class="form-control"
                                          type="date"
                                          v-model="tanggal_selesai_pelaksanaan"
                                        ></b-form-input>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Penjelasan Singkat</td>
                              <td colspan="3">
                                <textarea
                                  cols="30"
                                  rows="10"
                                  class="form-control"
                                  v-model="penjelasan_singkat"
                                ></textarea>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Nilai Jasa Vendor/Konsultan
                              </td>
                              <td colspan="3">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="nilai_jasa_vendor"
                                  v-on:input="
                                    nilai_jasa_vendor =
                                      numberFormat(nilai_jasa_vendor)
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Kategori Lingkup Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Kategori</span>
                      </template>
                      <div class="p-2">
                        <table
                          class="table mb-0 table-bordered table-condensed table-hover"
                        >
                          <thead>
                            <tr class="bg-light text-center">
                              <th colspan="2">Kategori</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Departement</td>
                              <td>
                                <v-select
                                  placeholder="-Pilih Departemen-"
                                  :options="master_departement"
                                  label="nama"
                                  v-model="master_departemen_selected"
                                ></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Tipe</td>
                              <td>
                                <v-select
                                  placeholder="-Pilih Tipe-"
                                  :options="['consultant', 'vendor']"
                                  v-model="tipe_selected"
                                  @update:modelValue="tipeVendorChange"
                                ></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10px">Kategori</td>
                              <td>
                                <v-select
                                  placeholder="-Pilih Kategori-"
                                  :options="master_kategori"
                                  label="tipe"
                                  v-model="master_kategori_selected"
                                  @update:modelValue="kategoriChange"
                                ></v-select>
                                <div v-if="master_kategori_selected_lainnya">
                                  <b-form-input
                                    type="text"
                                    v-model="kategori_lainnya"
                                  ></b-form-input>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10px">PIC pelaksana</td>
                              <td>
                                <v-select
                                  v-model="master_user_selected"
                                  :options="master_user"
                                  label="name"
                                  multiple
                                ></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10px">PIC Vendor/Konsultan</td>
                              <td>
                                <b-form-input
                                  type="text"
                                  v-model="pic_vendor"
                                ></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10px">
                                No HP PIC Vendor/Konsultan
                              </td>
                              <td>
                                <input type="text" maxlength="15" v-model="no_pic_vendor" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Pemberi Sponsor Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block"
                          >Vendor / Konsultan</span
                        >
                      </template>
                      <div class="p-2">
                        <table
                          class="table mb-0 table-bordered table-condensed table-hover"
                        >
                          <thead>
                            <tr class="bg-light">
                              <th class="text-center" colspan="2">
                                Vendor/Konsultan
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10px">Penjelasan Keuntungan</td>
                              <td>
                                <textarea
                                  name="penjelasan_keuntungan"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  class="form-control"
                                  v-model="penjelasan_keuntungan"
                                ></textarea>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10px">
                                Upload File SPK <br /><small style="color: red"
                                  >({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small
                                >
                              </td>
                              <td>
                                <input
                                  type="file"
                                  :accept="label_upload_tipe"
                                  id="uploadFileSPK"
                                  @change="uploadFileSPK"
                                  class="form-control"
                                />
                                <input type="hidden" v-model="file_spk" />
                                <div
                                  class="respond-input-file float-left"
                                  id="uploadLoadingFileSPK"
                                >
                                  <div
                                    v-if="file_spk"
                                    class="btn btn-info btn-sm mt-1"
                                    @click="downloadHandle()"
                                  >
                                    <i class="fa fa-download"></i> Download
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Dokumen Pendukung</td>
                              <td colspan="3">
                                <table
                                  class="table table-condensed table-bordered table-hover"
                                >
                                  <thead>
                                    <tr class="bg-light">
                                      <th class="text-center">Nama Dokumen</th>
                                      <th>
                                        File <br /><small style="color: red"
                                          >({{ textlabel_upload_tipe }}, Ukuran
                                          Maksimal Upload:
                                          {{
                                            formatBytes(max_upload_size)
                                          }})</small
                                        >
                                      </th>
                                      <th
                                        style="width: 25px"
                                        class="text-center"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-success btn-sm"
                                          v-on:click="addRowDokumenPendukung()"
                                        >
                                          <i class="fa fa-plus"></i>
                                        </button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        row_dokumen_pendukung_value,
                                        row_dokumen_pendukung_key
                                      ) in row_dokumen_pendukung"
                                      :key="row_dokumen_pendukung_key"
                                    >
                                      <td>
                                        <b-form-input
                                          type="text"
                                          v-model="
                                            row_dokumen_pendukung_value.name
                                          "
                                          placeholder="Nama Dokumen"
                                        ></b-form-input>
                                      </td>
                                      <td>
                                        <input
                                          type="file"
                                          :accept="label_upload_tipe"
                                          class="form-control"
                                          :id="
                                            'uploadFileDokumenPendukung' +
                                            row_dokumen_pendukung_key
                                          "
                                          @change="
                                            uploadFileDokumenPendukung(
                                              row_dokumen_pendukung_key,
                                              row_dokumen_pendukung_value.name
                                            )
                                          "
                                        />
                                        <input
                                          type="hidden"
                                          v-model="
                                            row_dokumen_pendukung_value.dir
                                          "
                                        />
                                        <div
                                          v-if="
                                            row_dokumen_pendukung_db >=
                                              row_dokumen_pendukung_key + 1 &&
                                            row_dokumen_pendukung_db != 0
                                          "
                                        >
                                          <div
                                            class="respond-input-file float-left"
                                            :id="
                                              'uploadLoadingDokumenPendukung' +
                                              row_dokumen_pendukung_key
                                            "
                                          >
                                            <div
                                              class="btn btn-info btn-sm mt-1"
                                              @click="
                                                downloadEachAttachment(
                                                  row_dokumen_pendukung_key.dir
                                                )
                                              "
                                            >
                                              <i class="fa fa-download"></i>
                                              Download
                                            </div>
                                          </div>
                                        </div>
                                        <div v-else>
                                          <div
                                            class="respond-input-file float-left"
                                            :id="
                                              'uploadLoadingDokumenPendukung' +
                                              row_dokumen_pendukung_key
                                            "
                                          ></div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          class="btn btn-danger btn-sm"
                                          v-on:click="
                                            removeRowDokumenPendukung(
                                              row_dokumen_pendukung_key
                                            )
                                          "
                                        >
                                          <i class="bx bx-minus"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Keterangan Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Keterangan</span>
                      </template>
                      <div class="p-2">
                        <table
                          class="table mb-0 table-bordered table-condensed table-hover"
                        >
                          <thead>
                            <tr class="bg-light">
                              <th colspan="6">Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Nominal Akhir</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="nominal_akhir"
                                  v-on:input="
                                    nominal_akhir = numberFormat(nominal_akhir)
                                  "
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Fee Termasuk Pajak ?</td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input
                                      class="form-check-input mt-0"
                                      type="radio"
                                      value="Ya"
                                      v-model="is_fee_pajak"
                                    />
                                    Ya &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input
                                      class="form-check-input mt-0"
                                      type="radio"
                                      value="Tidak"
                                      v-model="is_fee_pajak"
                                    />
                                    Tidak &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Upload File Penilaian Vendor/Konsultan
                                <br /><small style="color: red"
                                  >({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small
                                >
                              </td>
                              <td>
                                <input
                                  type="file"
                                  :accept="label_upload_tipe"
                                  class="form-control"
                                  id="uploadFilePenilaian"
                                  @change="uploadFilePenilaian"
                                />
                                <input type="hidden" v-model="file_penilaian" />
                                <div
                                  class="respond-input-file float-left"
                                  id="uploadLoadingFilePenilaian"
                                >
                                  <div
                                    v-if="file_penilaian"
                                    class="btn btn-info btn-sm mt-1"
                                    @click="downloadHandle()"
                                  >
                                    <i class="fa fa-download"></i> Download
                                  </div>
                                </div>
                                <small style="color: red"
                                  >*Apabila dalam hal pemilihan vendor/konsultan
                                  dilakukan dengan cara pitching/Beauty
                                  Contest</small
                                >
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Termin Pembayaran</td>
                              <td>
                                <v-select
                                  placeholder="-Pilih Termin Pembayaran-"
                                  :options="[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                  ]"
                                  v-model="termin_pembayaran"
                                  @update:modelValue="pilihTerminPembayaran"
                                ></v-select>
                              </td>
                            </tr>
                            <tr v-if="showRealisasiPembayaran == false"></tr>
                            <tr
                              v-else
                              v-for="(item, index) in realisasi_pembayaran"
                              :key="index"
                            >
                              <td colspan="6">
                                <div class="row col-md-12">
                                  <div class="col-md-3">
                                    Tanggal Realisasi (Termin {{ index + 1 }})
                                  </div>
                                  <div class="col-md-3">
                                    <input
                                      type="date"
                                      class="form-control"
                                      v-model="item.tanggal_bayar"
                                    />
                                  </div>
                                  <div class="col-md-3">
                                    Bukti Realisasi <br /><small
                                      style="color: red"
                                      >({{ textlabel_upload_tipe }}, Ukuran
                                      Maksimal Upload:
                                      {{ formatBytes(max_upload_size) }})</small
                                    >
                                  </div>
                                  <div class="col-md-3">
                                    <input
                                      type="file"
                                      :accept="label_upload_tipe"
                                      class="form-control"
                                      :id="
                                        'uploadFileDokumenBuktiTransfer' + index
                                      "
                                      @change="
                                        uploadFileDokumenBuktiTransfer(index)
                                      "
                                    />
                                    <input
                                      type="hidden"
                                      v-model="item.bukti_bayar"
                                    />
                                    <div v-if="bukti_transfer_db >= index + 1">
                                      <div
                                        class="respond-input-file float-left"
                                        :id="
                                          'uploadLoadingDokumenBuktiTransfer' +
                                          index
                                        "
                                      >
                                        <div
                                          class="btn btn-info btn-sm mt-1"
                                          :id="'file_lampiran' + index"
                                          @click="
                                            downloadEachAttachment(
                                              item.bukti_bayar
                                            )
                                          "
                                        >
                                          <i class="fa fa-eye"></i> Lihat File
                                        </div>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div
                                        class="respond-input-file float-left"
                                        :id="
                                          'uploadLoadingDokumenBuktiTransfer' +
                                          index
                                        "
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Project/Kegiatan Terlaksana ?
                              </td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input
                                      class="form-check-input mt-0"
                                      type="radio"
                                      value="Ya"
                                      v-model="is_projek_terlaksana"
                                    />
                                    Ya &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input
                                      class="form-check-input mt-0"
                                      type="radio"
                                      value="Tidak"
                                      v-model="is_projek_terlaksana"
                                    />
                                    Tidak &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Catatan Project</td>
                              <textarea
                                id=""
                                cols="30"
                                rows="10"
                                class="form-control"
                                v-model="catatan_project"
                              ></textarea>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Dasar Pertimbangan Memilih Vendor/Konsultan
                              </td>
                              <textarea
                                id=""
                                cols="30"
                                rows="10"
                                class="form-control"
                                v-model="dasar_pertimbangan"
                              ></textarea>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Penilaian Hasil Akhir Vendor/Konsultan
                              </td>
                              <td>
                                <v-select
                                  placeholder="-Pilih Penilaian-"
                                  :options="[
                                    'Very Good',
                                    'Good',
                                    'Netral',
                                    'Bad',
                                  ]"
                                  v-model="penilaian_hasil_selected"
                                ></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Keterangan Penilaian</td>
                              <td>
                                <textarea
                                  id=""
                                  cols="30"
                                  rows="10"
                                  class="form-control"
                                  v-model="keterangan_penilaian"
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-md-6"></div>
                          <div class="col-md-6 text-end">
                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> SIMPAN</b-button>
                          </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <router-link
                    :to="{ name: 'vendor' }"
                    class="btn bg-secondary text-white"
                  >
                    <i class="fa fa-chevron-left"></i> Kembali Ke List
                    Vendor/Konsultan
                  </router-link>
                </div>
                <!-- <div class="col-md-6 text-end">
                  <b-button type="submit" variant="primary" class="m-1"
                    ><i class="fa fa-save"></i> SIMPAN</b-button
                  >
                </div> -->
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
